/**
 * Page Mentions Légales Nader Abou Anas
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import Layout from '../components/body/layout';
import Seo from '../components/seo';
import ContainerWithSideBarre from '../components/body/ContainerWithSideBarre';
import ContentLeft from '../components/body/ContentLeft';
import ContentRight from '../components/body/ContentRight';
import Container from '../components/body/Container';
import SideBar from '../components/marketing/sideBar/SideBar';
import StickyBox from 'react-sticky-box';

const MentionsLegales = ({ data }) => {
	return (
		<Layout title="MENTIONS LÉGALES">
			<Seo title="Nader Abou Anas | Mentions légales" />

			<Container>
				<ContainerWithSideBarre>
					<StickyBox
						offsetTop={20}
						offsetBottom={20}
						className="w-full lg:w-8/12 stickyBox"
					>
						<ContentLeft backgroundDisplay={false}>
							<div>
								<p>
									Merci de lire avec attention les différentes modalités
									d’utilisation du présent site avant d’y parcourir ses pages. En
									vous connectant sur ce site, vous acceptez, sans réserves, les
									présentes modalités.
								</p>
								<p>
									Aussi, conformément à l’article n°6 de la Loi n°2004-575 du 21
									Juin 2004 pour la confiance dans l’économie numérique, les
									responsables du présent site internet dourous.net&nbsp;sont :
								</p>
								<p>
									<b>Éditeur du Site :</b>
								</p>
								<p>NADER ABOU ANAS</p>
								<p>Responsable éditorial :&nbsp;Nader Abou Anas</p>
								<p>Email : naderabouanas.site@gmail.com</p>
								<p>Site Web : dourous.net</p>
								<p>
									<b>Hébergement :</b>
								</p>
								<p>
									Hébergeur : Firebase
									<br />
									188 King ST, San Francisco, CA 94107, United States
									<br />
									Site Web :&nbsp;
									<a
										href="https://firebase.google.com/"
										target="_blank"
										rel="noopener"
									>
										Firebase
									</a>
								</p>
								<p>
									<b>Développement</b>
									<b>&nbsp;:</b>
								</p>
								<p>Arnaud Desportes, SASU Remote Monkey, web developement.</p>
								<p>Adresse : 1553 route de saint Victor 38300 SUCCIEU France</p>
								<p>
									Site Web :{' '}
									<a
										href="https://arnaud-desportes.fr/"
										target="_blank"
										rel="noopener"
									>
										arnaud-desportes.fr
									</a>
								</p>
								<p>
									<b>Conditions d’utilisation :</b>
								</p>
								<p>
									Ce site (dourous.net) est proposé en différents langages web
									(HTML5, Javascript, CSS, React) pour un meilleur confort
									d’utilisation et un graphisme plus agréable.
								</p>
								<p>
									Nous vous recommandons de recourir à des navigateurs modernes
									comme Safari, Firefox, Google Chrome.
								</p>
								<p>
									Dourous.net<b>&nbsp;</b>met en œuvre tous les moyens dont il
									dispose, pour assurer une information fiable et une mise à jour
									fiable de ses sites internet.
								</p>
								<p>
									Toutefois, des erreurs ou omissions peuvent survenir.
									L’internaute devra donc s’assurer de l’exactitude des
									informations auprès de dourous.net , et signaler toutes
									modifications du site qu’il jugerait utile. dourous.net n’est en
									aucun cas responsable de l’utilisation faite de ces
									informations, et de tout préjudice direct ou indirect pouvant en
									découler.
								</p>
								<p>
									<b>Cookies</b> : Le site dourous.net peut-être amené à vous
									demander l’acceptation des cookies pour des besoins de
									statistiques et d’affichage. Un cookie&nbsp;est une information
									déposée sur votre disque dur par le serveur du site que vous
									visitez.
								</p>
								<p>
									Il contient plusieurs données qui sont stockées sur votre
									ordinateur dans un simple fichier texte auquel un serveur accède
									pour lire et enregistrer des informations . Certaines parties de
									ce site ne peuvent être fonctionnelles sans l’acceptation de
									cookies.
								</p>
								<p>
									<b>Liens hypertextes :</b> Les sites internet de peuvent offrir
									des liens vers d’autres sites internet ou d’autres ressources
									disponibles sur Internet. Nader Abou Anas ne dispose d’aucun
									moyen pour contrôler les sites en connexion avec ses sites
									internet.
								</p>
								<p>
									Nader Abou Anas ne répond pas de la disponibilité de tels sites
									et sources externes, ni ne la garantit. Elle ne peut être tenue
									pour responsable de tout dommage, de quelque nature que ce soit,
									résultant du contenu de ces sites ou sources externes, et
									notamment des informations, produits ou services qu’ils
									proposent, ou de tout usage qui peut être fait de ces éléments.
									Les risques liés à cette utilisation incombent pleinement à
									l’internaute, qui doit se conformer à leurs conditions
									d’utilisation.
								</p>
								<p>
									Les utilisateurs, les abonnés et les visiteurs des sites
									internet &nbsp;ne peuvent pas mettre en place un hyperlien en
									direction de ce site sans l’autorisation expresse et préalable
									de Nader Abou Anas.
								</p>
								<p>
									Dans l’hypothèse où un utilisateur ou visiteur souhaiterait
									mettre en place un hyperlien en direction d’un des sites
									internet de Nader Abou Anas, il lui appartiendra d’adresser un
									email accessible sur le site afin de formuler sa demande de mise
									en place d’un hyperlien.
								</p>
								<p>
									Nader Abou Anas se réserve le droit d’accepter ou de refuser un
									hyperlien sans avoir à en justifier sa décision.
								</p>
								<p>
									<b>Services fournis :</b>
								</p>
								<p>
									L’ensemble des activités de la société ainsi que ses
									informations sont présentés sur notre site dourous.net
								</p>
								<p>
									Nader Abou Anas s’efforce de fournir sur le site dourous.net des
									informations aussi précises que possible. Les renseignements
									figurant sur le site dourous.net ne sont pas exhaustifs et les
									photos non contractuelles.
								</p>
								<p>
									Ils sont donnés sous réserve de modifications ayant été
									apportées depuis leur mise en ligne. Par ailleurs, tous les
									informations indiquées sur le site www.anthedesign.fr
									<b>&nbsp;</b>sont données à titre indicatif, et sont
									susceptibles de changer ou d’évoluer sans préavis.
								</p>
								<p>
									<b>Limitation contractuelles sur les données :</b>
								</p>
								<p>
									Les informations contenues sur ce site sont aussi précises que
									possible et le site remis à jour à différentes périodes de
									l’année, mais peut toutefois contenir des inexactitudes ou des
									omissions.
								</p>
								<p>
									Si vous constatez une lacune, erreur ou ce qui parait être un
									dysfonctionnement, merci de bien vouloir le signaler par
									courriel, à l’adresse xxxx@xxxx.com, en décrivant le problème de
									la manière la plus précise possible (page posant problème, type
									d’ordinateur et de navigateur utilisé, …).
								</p>
								<p>
									Tout contenu téléchargé se fait aux risques et périls de
									l’utilisateur et sous sa seule responsabilité. En conséquence,
									ne saurait être tenu responsable d’un quelconque dommage subi
									par l’ordinateur de l’utilisateur ou d’une quelconque perte de
									données consécutives au téléchargement.
								</p>
								<p>
									De plus, l’utilisateur du site s’engage à accéder au site en
									utilisant un matériel récent, ne contenant pas de virus et avec
									un navigateur de dernière génération mis-à-jour.
								</p>
								<p>
									Les liens hypertextes mis en place dans le cadre du présent site
									internet en direction d’autres ressources présentes sur le
									réseau Internet ne sauraient engager la responsabilité de Nader
									Abou Anas.
								</p>
								<p>
									<b>Propriété intellectuelle :</b>
								</p>
								<p>
									Tout le contenu du présent site dourous.net, incluant, de façon
									non limitative, les graphismes, images, textes, vidéos,
									animations, sons, logos, gifs et icônes ainsi que leur mise en
									forme sont la propriété exclusive de la société à l’exception
									des marques, logos ou contenus appartenant à d’autres sociétés
									partenaires ou auteurs.
								</p>
								<p>
									Toute reproduction, distribution, modification, adaptation,
									retransmission ou publication, même partielle, de ces différents
									éléments est strictement interdite sans l’accord exprès par
									écrit de Nader Abou Anas. Cette représentation ou reproduction,
									par quelque procédé que ce soit, constitue une contrefaçon
									sanctionnée par les articles L.335-2 et suivants du Code de la
									propriété intellectuelle. Le non-respect de cette interdiction
									constitue une contrefaçon pouvant engager la responsabilité
									civile et pénale du contrefacteur. En outre, les propriétaires
									des Contenus copiés pourraient intenter une action en justice à
									votre encontre.
								</p>
								<p>
									<b>Déclaration à la CNIL :</b>
								</p>
								<p>
									Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la
									loi 2004-801 du 6 août 2004 relative à la protection des
									personnes physiques à l’égard des traitements de données à
									caractère personnel) relative à l’informatique, aux fichiers et
									aux libertés, ce site a fait l’objet d’une déclaration xxxxxxx
									auprès de la Commission nationale de l’informatique et des
									libertés (<a href="http://www.cnil.fr/">www.cnil.fr</a>).
								</p>
								<p>
									<b>Litiges :</b>
								</p>
								<p>
									Les présentes conditions du site dourous.net&nbsp;sont régies
									par les lois françaises et toute contestation ou litiges qui
									pourraient naître de l’interprétation ou de l’exécution de
									celles-ci seront de la compétence exclusive des tribunaux dont
									dépend le siège social de la société. La langue de référence,
									pour le règlement de contentieux éventuels, est le français.
								</p>
								<p>
									<b>Données personnelles :</b>
								</p>
								<p>
									De manière générale, vous n’êtes pas tenu de nous communiquer
									vos données personnelles lorsque vous visitez notre site
									Internet dourous.net
								</p>
								<p>
									Cependant, ce principe comporte certaines exceptions. En effet,
									pour certains services proposés par notre site, vous pouvez être
									amenés à nous communiquer certaines données telles que : votre
									nom, votre fonction, le nom de votre société, votre adresse
									électronique, et votre numéro de téléphone. Tel est le cas
									lorsque vous remplissez le formulaire qui vous est proposé en
									ligne.
								</p>
								<p>
									Dans tous les cas, vous pouvez refuser de fournir vos données
									personnelles. Dans ce cas, vous ne pourrez pas utiliser les
									services du site, notamment celui de solliciter des
									renseignements sur notre société, ou de recevoir les lettres
									d’information.
								</p>
								<p>
									Enfin, nous pouvons collecter de manière automatique certaines
									informations vous concernant lors d’une simple navigation sur
									notre site internet, notamment : des informations concernant
									l’utilisation de notre site, comme les zones que vous visitez et
									les services auxquels vous accédez, votre&nbsp;adresse IP, le
									type de votre navigateur, vos temps d’accès.
								</p>
								<p>
									De telles informations sont utilisées exclusivement à des fins
									de statistiques internes, de manière à améliorer la qualité des
									services qui vous sont proposés. Les bases de données sont
									protégées par les dispositions de la loi du 1er juillet 1998
									transposant la directive 96/9 du 11 mars 1996 relative à la
									protection juridique des bases de données.
								</p>
							</div>
						</ContentLeft>
					</StickyBox>

					<StickyBox
						offsetTop={20}
						offsetBottom={20}
						className="w-full lg:w-4/12 stickyBox"
					>
						<ContentRight>
							<SideBar modCategoriesDisplay={false} />
						</ContentRight>
					</StickyBox>
				</ContainerWithSideBarre>
			</Container>
		</Layout>
	);
};

export default MentionsLegales;
